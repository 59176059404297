import React from 'react'
import styled from 'styled-components'
import { useStaticQuery, graphql } from 'gatsby'
import { GatsbyImage, GatsbyImageProps } from 'gatsby-plugin-image'

import Theme from 'styles/_theme'
import GlobalStyle from 'styles/_global'

import { AnimationFade } from 'libs/animation'

import { Wrapper } from 'styles/_mixins'

import Seo from 'components/Seo/Seo'
import Layout from 'components/Layout/Layout'
import Button from 'components/Button/Button'

const Container = styled.section`
  position: relative;
  width: 100%;
  height: calc(100vh - 10rem);

  &:before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    z-index: 2;
    width: 100%;
    height: 100%;
    background: ${({ theme: { colors } }) => colors.opblack};
  }

  & ${Wrapper} {
    display: flex;
    justify-content: center;
    flex-direction: column;
    text-align: center;
    height: 100%;
  }
`

const Content = styled.div`
  position: relative;
  z-index: 3;
  width: 100%;
  height: fit-content;
`

const Title = styled.h1`
  margin-bottom: 0.5rem;
  font-size: clamp(7rem, 14vw, 15rem);
  color: ${({ theme: { colors } }) => colors.white};
`

const Description = styled.p`
  text-transform: uppercase;
  margin-bottom: 7rem;
  font-size: 1.8rem;
  color: ${({ theme: { colors } }) => colors.white};
`

const Image = styled(GatsbyImage)<GatsbyImageProps>`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
`

const NotFoundPage = () => {
  const datas = useStaticQuery(graphql`
    query {
      markdownRemark(fileAbsolutePath: { regex: "/error/" }) {
        frontmatter {
          id
          slug
          title
          descrip
          linkTitle
          linkTo
          seoTitle
          seoDescription
          images {
            src {
              childImageSharp {
                gatsbyImageData(layout: FULL_WIDTH, quality: 100, formats: [AUTO, WEBP])
              }
            }
            alt
          }
        }
      }
    }
  `)

  const { title, descrip, linkTitle, linkTo, seoTitle, seoDescription, images } =
    datas.markdownRemark.frontmatter

  return (
    <Theme>
      <GlobalStyle />
      <Layout backgrounds={false}>
        <Seo title={seoTitle} description={seoDescription} />
        <Container>
          <AnimationFade rootMargin='0px 0px' custom={0} durarion='1'>
            <Image image={images.src.childImageSharp.gatsbyImageData} alt={images.alt} />
            <Wrapper>
              <Content>
                <Title>{title}</Title>
                <Description>{descrip}</Description>

                <Button to={linkTo}>{linkTitle}</Button>
              </Content>
            </Wrapper>
          </AnimationFade>
        </Container>
      </Layout>
    </Theme>
  )
}

export default NotFoundPage
